import { IResult } from "@arsenale/lib/monad";
import { createContext } from "react";

export interface ToasterContextType {
    toast: IResult<string, string> | null;
    show: boolean;
    setToast: (toast: IResult<string, string>) => void;
}

const ToasterContext = createContext<ToasterContextType>(null as unknown as ToasterContextType);

export default ToasterContext;